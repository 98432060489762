import React from "react"
import Layout from "../components/layout"
import { Title, Overview, TitleContent, WorkedWith } from "../components/parts"

const Hackathons = () => (
  <Layout title="Hackathons">
    <Title heading="Hackathons" sub="Ship in 48 hours" />
    <Overview
      project="hackathons"
      text={["A fun way to keep up with new things in tech and friends."]}
    />
    <TitleContent title="AB InBev">
      <p>
        With the specific objective of optimizing AB InBev's retail sales, our
        team of four put their minds to work at the 36-hour (spanning two
        nights) event held at AB InBev's Global Analytics Center in Bengaluru,
        India.
      </p>
      <div className="section-bottom">
        <span className="text-tag">Winner</span>
        <span className="text-tag">2017</span>
        <span className="text-tag">Bengaluru, India / New York, USA</span>
        <div>
          <img
            src="/images/hackathons/abinbev-dashboard.png"
            className="bordered"
            style={{ marginBottom: 12 }}
            alt="abinbev-dashboard"
          />
          <img src="/images/hackathons/abinbev.jpg" alt="abinbev" />
        </div>
      </div>
      <WorkedWith
        label="Teamed with"
        people={["ramanan", "nagappan", "abishek"]}
      />
    </TitleContent>
    <TitleContent title="Smart Living hackathon">
      <p>
        The team developed an application that simplifies the process of buying
        groceries – users only need to use their voice to make purchases online.
      </p>
      <div className="section-bottom">
        <span className="text-tag">Runner up</span>
        <span className="text-tag">2018</span>
        <span className="text-tag">Singapore</span>
        <div>
          <img src="/images/hackathons/smart-living.jpeg" alt="smartliving" />
        </div>
      </div>
      <WorkedWith
        label="Teamed with"
        people={["vanchi", "divya", "nagappan"]}
      />
    </TitleContent>
    <TitleContent title="India GeoHack">
      <p>
        Estimate the property-tax potential of a city using satellite images.
      </p>
      <div className="section-bottom">
        <span className="text-tag">Runner up</span>
        <span className="text-tag">2017</span>
        <span className="text-tag">Bengaluru, India</span>
      </div>
      <WorkedWith label="Teamed with" people={["vinothgeo", "nagappan"]} />
    </TitleContent>
  </Layout>
)

export default Hackathons
